<template>
  <div class="landing-container">
    <h1 class="title">Jerome Brown</h1>
    <p class="subtitle">Innovator &amp; Technology Enthusiast</p>
    <h3><a href="mailto:jbreview@outlook.com">jbreview@outlook.com</a></h3>
    <router-link to="/about" class="cta-btn">Learn More</router-link>

    <!-- Company and Product Links -->
    <div class="company-links">
      <a href="https://sisoftware.xyz" target="_blank" class="company-link" aria-label="SI Software">
        Visit SI Software
      </a>
      <a href="https://thryveconnect.com" target="_blank" class="product-link" aria-label="Thryve Connect">
        Explore Thryve!
      </a>
      <a href="https://adametrics.vercel.app/" target="_blank" class="product-link" aria-label="Adametrics">
        Try Adametrics in Beta!
      </a>
      <a href="https://savvyfi.vercel.app/" target="_blank" class="product-link" aria-label="Adametrics">
       Freelancers can be Finance Pros too
      </a>
      <!-- <a href="https://adametrics.vercel.app/" target="_blank" class="product-link" aria-label="Adametrics" style="font-size: 16px; font-weight: bold; color: #777777; text-decoration: none; background-color: #ff6b6b; padding: 8px 16px; border-radius: 20px; transition: all 0.3s ease; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
        Try Adametrics in Beta!
      </a> -->

      
    </div>

    <!-- Technology Icons and Built With Label -->
    <div class="tech-footer">
      <p class="built-with">Built with</p>
      <div class="tech-icons">
        <a href="https://vuejs.org/" target="_blank" class="icon-link" aria-label="Vue.js">
          <img src="https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg" alt="Vue.js logo" />
        </a>
        <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" class="icon-link" aria-label="JavaScript">
          <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" alt="JavaScript logo" />
        </a>
        <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="_blank" class="icon-link" aria-label="CSS3">
          <img src="https://upload.wikimedia.org/wikipedia/commons/6/62/CSS3_logo.svg" alt="CSS3 logo" />
        </a>
      </div>
    </div>

    <!-- Substack Link with Icon -->
    <div class="substack-footer">
      <a href="https://jeromejbrown.substack.com/" target="_blank" class="substack-link bounce-once" aria-label="Substack">
        <img src="/assets/substack.png" alt="Substack icon" class="substack-icon" />
        <span>I write stuff here</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* Google Font Import */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

/* Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes bounceIn {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes bounceMultiple {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Landing Container */
.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f4f4f9;
  color: #333;
  text-align: center;
  padding: 20px;
  animation: fadeIn 1.5s ease-out;
}

.title {
  font-size: 3.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  animation: slideInLeft 1.5s ease-out;
}

.subtitle {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 30px;
}

.cta-btn {
  padding: 12px 30px;
  font-size: 1.1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  margin-bottom: 40px;
  animation: bounceIn 1.5s ease-out;
}

.cta-btn:hover {
  background-color: #0056b3;
}

/* Company Links Styling */
.company-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.company-link,
.product-link {
  font-size: 1.1rem;
  font-weight: 500;
  color: #007bff;
  text-decoration: none;
  transition: transform 0.3s, color 0.3s;
}

.company-link:hover,
.product-link:hover {
  transform: scale(1.05);
  color: #0056b3;
}

/* Technology Icons Section */
.tech-footer {
  position: fixed;
  bottom: 20px;
  left: 20px;
  text-align: left;
}

.built-with {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #777;
  margin-bottom: 5px;
}

.tech-icons {
  display: flex;
  gap: 15px;
}

.icon-link img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

.icon-link img:hover {
  animation: pulse 1s infinite;
}

/* Substack Footer */
.substack-footer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.substack-link {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  transition: transform 0.3s, color 0.3s;
}

.substack-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

/* Bounce animation for "I write stuff here" link */
.bounce-once {
  animation: bounceMultiple 2s ease-out;
}

.substack-link:hover {
  transform: scale(1.05);
  color: #0056b3;
}

h3 a {
  color: inherit;
  text-decoration: none;
}

h3 a:hover {
  text-decoration: underline;
}
</style>
